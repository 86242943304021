import { useContext } from "react";
import { ClalendarContext } from "../Providers/CalendarProvider";
function ColorPicker() {
  const { events, colors, selectedColor, setSelectedColor } =
    useContext(ClalendarContext);
  return (
    <>
      {colors.map((i) => (
        <div
          className={`color-picker mx-2 ${
            selectedColor == i.colorid ? "selected-color" : ""
          }`}
          style={{ backgroundColor: i.colorbg }}
          onClick={() => setSelectedColor(i.colorid)}></div>
      ))}
    </>
  );
}
export default ColorPicker;
