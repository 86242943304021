import "./Styles/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./Pages/Langing";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import _404 from "./Pages/404";
import { UserProvider } from "./Providers/UserProvider";
import Welcome from "./Pages/Welcome";
import App from "./Pages/App/App";
import Calendar from "./Pages/App/Calendar";
import Analytics from "./Pages/App/Analytics";
import { CalendarProvider } from "./Providers/CalendarProvider";
import { ModalProvider } from "./Providers/ModaProvider";
import { ModalContext } from "./Providers/ModaProvider";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <ModalProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route
            path="/auth/login"
            element={
              <UserProvider>
                <Login />
              </UserProvider>
            }
          />
          <Route
            path="/auth/register"
            element={
              <UserProvider>
                <Register />
              </UserProvider>
            }
          />
          <Route
            path="/welcome"
            element={
              <UserProvider>
                <Welcome />
              </UserProvider>
            }
          />
          <Route
            path="/dashboard"
            element={
              <UserProvider>
                <App />
              </UserProvider>
            }
          />
          <Route
            path="/analytics"
            element={
              <UserProvider>
                <Analytics />
              </UserProvider>
            }
          />
          <Route
            path="/calendar"
            element={
              <UserProvider>
                <CalendarProvider>
                  <Calendar />
                </CalendarProvider>
              </UserProvider>
            }
          />
          <Route path="*" element={<_404 />} />
        </Routes>
      </BrowserRouter>
    </ModalProvider>
  </>
);
