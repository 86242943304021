import React, { useState, useEffect, useContext } from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { ClalendarContext } from "../Providers/CalendarProvider";
function MiniCalendar() {
  const date = new Date();
  const [currentDate, setCurrentDate] = useState({
    year: date.getFullYear(),
    month: date.getMonth(),
    day: date.getDate(),
  });
  const { events } = useContext(ClalendarContext);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthDictionary = {
    January: "Január",
    February: "Február",
    March: "Március",
    April: "Április",
    May: "Május",
    June: "Június",
    July: "Július",
    August: "Augusztus",
    September: "Szeptember",
    October: "Október",
    November: "November",
    December: "December",
  };

  const dayDictionary = {
    Mon: "Hét.",
    Tue: "Ked.",
    Wed: "Szer.",
    Thu: "Csüt.",
    Fri: "Pén.",
    Sat: "Szom.",
    Sun: "Vas.",
  };

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const manipulate = () => {
    const { year, month } = currentDate;

    let dayone = new Date(year, month, 1).getDay();

    let lastdate = new Date(year, month + 1, 0).getDate();

    let dayend = new Date(year, month, lastdate).getDay();

    let monthlastdate = new Date(year, month, 0).getDate();

    let lit = [];

    for (let i = dayone; i > 0; i--) {
      lit.push(
        <li className="inactive" key={`prev-${i}`}>
          {monthlastdate - i + 1}
        </li>
      );
    }
    for (let i = 1; i <= lastdate; i++) {
      const isToday =
        i === date.getDate() &&
        month === new Date().getMonth() &&
        year === new Date().getFullYear()
          ? "active_"
          : "";
      lit.push(
        <li className={isToday} key={`curr-${i}`}>
          {i}
        </li>
      );
    }
    for (let i = dayend; i < 6; i++) {
      lit.push(
        <li className="inactive" key={`next-${i}`}>
          {i - dayend + 1}
        </li>
      );
    }

    return lit;
  };

  const handlePrevNextClick = (direction) => {
    let { year, month } = currentDate;
    if (direction === "prev") {
      month -= 1;
    } else {
      month += 1;
    }
    if (month < 0 || month > 11) {
      const newDate = new Date(year, month, date.getDate());
      year = newDate.getFullYear();
      month = newDate.getMonth();
    }

    setCurrentDate({ ...currentDate, month, year });
  };

  return (
    <div className="mini-calendar-container sticky-top">
      <div className="mini-calendar">
        <div className="calendar-header">
          <span className="calendar-current-date">
            {monthDictionary[months[currentDate.month]]} {currentDate.year}
          </span>
          <div>
            <span
              id="calendar-prev"
              onClick={() => handlePrevNextClick("prev")}
              className="calendar-navigation me-2">
              <MdOutlineKeyboardArrowLeft size={25} className="ar" />
            </span>

            <span
              id="calendar-next"
              onClick={() => handlePrevNextClick("next")}
              className="calendar-navigation ms-3">
              <MdOutlineKeyboardArrowRight className="ar " size={25} />
            </span>
          </div>
        </div>
        <ul className="calendar-weekdays">
          {daysOfWeek.map((day) => (
            <li key={day}>{dayDictionary[day]}</li>
          ))}
        </ul>
        <ul className="calendar-dates">{manipulate()}</ul>
      </div>
    </div>
  );
}

export default MiniCalendar;
