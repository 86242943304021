import { useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import LI from "../Media/langing_header.png";
import AB from "../Media/about.svg";
import { motion } from "framer-motion";

function Landing() {
  const fadeIn = {
    offscreen: { opacity: 0, y: 50 },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", bounce: 0.1, duration: 1 },
    },
  };

  const navigator = useNavigate();

  return (
    <>
      <Navbar />
      {/* Első motion div az animációhoz */}
      <motion.div
        className="card-container"
        initial="offscreen"
        animate="onscreen" // animate helyett use animate
        variants={fadeIn}
        style={{ height: "100vh" }} // Teljes magasság
      >
        <div className="row">
          <div className="col-sm">
            <div className="d-flex justify-content-center align-items-center mt-sm-5">
              <img src={LI} alt="" className="img-fluid" />
            </div>
          </div>
          <div className="col-sm">
            <div className="d-none d-md-block">
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
            <div className="d-flex justify-content-center align-items-center mt-sm-5 p-4">
              <div className="mt-sm-5">
                <h1 className="customt">DayFlow</h1>
                <hr className="" />
                <h4 className="mt-3">
                  Üdvözöljük a naptárkezelő rendszerünkben! Itt könnyedén nyomon
                  követheti időpontjait, feladatait és határidőit.
                </h4>
                <button
                  className="custom_btn"
                  onClick={() => navigator("/auth/login")}>
                  Jelentkezz be!
                </button>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      <br />

      <br />

      <div className="container">
        <motion.div
          className="card-container"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: false, amount: 0.4 }}
          variants={fadeIn}>
          <div className="about" id="about">
            <h3 className="text-center">Üdvözöljük a DayFlow oldalán!</h3>
            <div
              className="row g-4 d-flex justify-content-center align-items-center"
              style={{ height: "100vh" }}>
              <div className="col-sm">
                <p className="fs-5">
                  A Dayflow egy modern és felhasználóbarát naptár, amely segít
                  rendszerezni a mindennapjaidat. Akár a munkád, a személyes
                  feladataid vagy a szabadidős tevékenységeid követése a cél, a
                  Dayflow könnyedén áttekinthetővé teszi az időbeosztásodat.
                </p>
                <p className="fs-5">
                  Egyszerűen használható felülettel, testreszabható
                  beállításokkal és emlékeztető funkciókkal biztosítjuk, hogy
                  soha ne maradj le egy fontos találkozóról vagy határidőről
                  sem. Tervezz előre, állíts fel prioritásokat, és érd el a
                  céljaidat hatékonyabb időkezeléssel – a Dayflow segítségével
                  minden napod gördülékenyen telhet!
                </p>
              </div>
              <div className="col-sm d-none d-sm-block">
                <div className="d-flex justify-content-center">
                  <img src={AB} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
      <div className="container">
        <motion.div
          className="card-container"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: false, amount: 0.4 }}
          variants={fadeIn}>
          <div className=" contact" id="contact">
            <h3 className="text-center">Vedd fel velünk a kapcsolatot!</h3>
            <div
              className="row g-4 d-flex justify-content-center align-items-center"
              style={{ height: "100vh" }}>
              <div className="col-sm"></div>
              <div className="col-sm">
                <h4 className="text-center">Üzenet küldése</h4>
                <div className="d-flex justify-content-between p-3">
                  <input
                    type="text"
                    placeholder="Vezetéknév"
                    className="me-2"
                  />
                  <input type="text" placeholder="Keresztnév" />
                </div>
                <div className="d-flex mx-3">
                  <input type="text" placeholder="E-mail" className="me-2" />
                  <input type="text" placeholder="Telefon" />
                </div>
                <div className="d-flex mt-3">
                  <textarea className="mx-3" placeholder="Üzenet"></textarea>
                </div>
                <div className="d-flex mx-3 mt-3">
                  <button>Küldés</button>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
}

export default Landing;
