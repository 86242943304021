import React, { useContext, useRef, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/hu"; // Magyar nyelv beállítása
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
} from "react-icons/md";
import ColorPicker from "./ColorPicker";
import { ClalendarContext } from "../Providers/CalendarProvider";
import { ModalContext } from "../Providers/ModaProvider";
import { useScroll } from "framer-motion";
const localizer = momentLocalizer(moment);

const CalendarComponent = () => {
  const { events, colors, _CreateEvent, selectedColor } =
    useContext(ClalendarContext);
  const { Modal, CloseModal, OpenModal, CreateModal } =
    useContext(ModalContext);
  const eventPropGetter = (event) => {
    return {
      style: {
        backgroundColor: event.bgcolor,
        border: "none",
        borderLeft: `5px solid ${event.bordercolor}`,
      },
    };
  };
  const formats = {
    timeGutterFormat: "HH:mm",
    eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
      `${localizer.format(start, "HH:mm", culture)} - ${localizer.format(
        end,
        "HH:mm",
        culture
      )}`,
    agendaTimeRangeFormat: ({ start, end }, culture, localizer) =>
      `${localizer.format(start, "HH:mm", culture)} - ${localizer.format(
        end,
        "HH:mm",
        culture
      )}`,
  };

  const messages = {
    next: <MdOutlineKeyboardArrowRight size={25} className="arrows" />,
    previous: <MdOutlineKeyboardArrowLeft size={25} className="arrows" />,
    today: <span className="today">Ma</span>,
    month: "Hónap",
    week: "Hét",
    day: "Nap",
    agenda: "Napló",
    date: "Dátum",
    time: "Idő",
    event: "Esemény",
    sun: "Vas",
    mon: "Hét",
    tue: "Ked",
    wed: "Sze",
    thu: "Csü",
    fri: "Pén",
    sat: "Szo",
    jan: "Jan",
    feb: "Feb",
    mar: "Márc",
    apr: "Ápr",
    may: "Máj",
    jun: "Jún",
    jul: "Júl",
    aug: "Aug",
    sep: "Szept",
    oct: "Okt",
    nov: "Nov",
    dec: "Dec",
  };

  const handleEventClick = (event) => {};

  const CustomToolbar = ({ label, onNavigate }) => {
    return (
      <div className="rbc-toolbar">
        <button onClick={() => onNavigate("PREV")} aria-label="Előző hónap">
          <MdOutlineKeyboardArrowLeft size={25} className="arrows" />
        </button>
        <button onClick={() => onNavigate("NEXT")} aria-label="Következő hónap">
          <MdOutlineKeyboardArrowRight size={25} className="arrows" />
        </button>
        <span className="rbc-toolbar-label">{label}</span>
        <button className="create" onClick={() => AddEvent()}>
          + Létrehozás
        </button>
      </div>
    );
  };
  const startRef = useRef();
  const endRef = useRef();
  const titleRef = useRef();
  const AddEvent = () => {
    var Create = (
      <>
        <div>
          <div className="mt-2">
            <label>Esemény (maximum 10 karakter)</label>
            <div className="input-group">
              <input type="text" className="form-control" ref={titleRef} />
            </div>
            <div className="row mt-2">
              <div className="col">
                <label>Esemény kezdete</label>
                <div className="input-group">
                  <input
                    type="datetime-local"
                    className="form-control"
                    min={new Date().toISOString().slice(0, 16)}
                    ref={startRef}
                  />
                </div>
              </div>
              <div className="col">
                <label>Esemény vége</label>
                <div className="input-group">
                  <input
                    type="datetime-local"
                    className="form-control"
                    min={new Date().toISOString().slice(0, 16)}
                    ref={endRef}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*  <div className="mt-2">
            <input type="checkbox" className="mx-2" />
            <span>Szeretnék bővebb leírást</span>
          </div> */}

          <div className="d-flex mt-2 justify-content-center">
            <ColorPicker />
          </div>
          <button
            className="mx-auto btn btn-primary w-100 mt-2"
            onClick={() =>
              _CreateEvent(
                titleRef.current.value,
                startRef.current.value.split("T").join(" "),
                endRef.current.value.split("T").join(" ")
              )
            }>
            Létrehozás
          </button>
        </div>
      </>
    );
    CreateModal("Esemény létrehozása", Create, true);
  };

  return (
    <>
      <Modal />
      <div className="h-100">
        <Calendar
          localizer={localizer}
          events={events}
          timeslots={1}
          startAccessor="start"
          endAccessor="end"
          defaultView="week"
          views={["week"]}
          step={60}
          selectable
          defaultDate={new Date()}
          style={{ height: "100%" }}
          eventPropGetter={eventPropGetter}
          formats={formats}
          messages={messages}
          onSelectEvent={handleEventClick}
          components={{
            toolbar: (props) => <CustomToolbar {...props} />,
          }}
        />
      </div>
    </>
  );
};

export default CalendarComponent;
