import { useState, createContext, useEffect, useContext } from "react";
import $ from "jquery";
import API from "../api.json";
import Cookies from "js-cookie";
import _Login from "../Pages/Login";
import { useLocation, useNavigate } from "react-router-dom";
import Auth from "./Auth";
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const navigator = useNavigate();
  const loc = useLocation();
  const [user, setUser] = useState(null);
  const SaveUser = (user) => {
    Cookies.set("_u", btoa(JSON.stringify(user)), { expires: 0.5 });
  };
  useEffect(() => {
    var _u = Cookies.get("_u");
    if (_u != null) setUser(JSON.parse(atob(Cookies.get("_u"))));
  }, []);
  const [LRerror, setLRerror] = useState("");
  const Login = (email, password) => {
    if (email.length == 0 || password.length == 0) {
      setLRerror("A mező(k) kitöltése kötelező!");
      return;
    }
    $.ajax({
      url: `${API.api}/user/login`,
      type: "post",
      data: { email: email, password, password },
      success: function (response) {
        SaveUser(response);
        setUser(response);
        navigator("/welcome");
      },
      error: function (response) {
        if (response.status == 401) {
          setLRerror(response.responseJSON.reason);
        } else {
          setLRerror("A bejelentkezés jelenleg nem elérhető!");
        }
      },
    });
  };
  const Register = (name, email, password) => {
    if (name.length == 0 || email.length == 0 || password.length == 0) {
      setLRerror("A mező(k) kitöltése kötelező!");
      return;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setLRerror("Az email cím nem megfelő! (minta@doamin.com)");
      return;
    }
    if (password.length < 5) {
      setLRerror("A jelszónak minimum 5 karakterből kell álnia!");
      return;
    }

    $.ajax({
      url: `${API.api}/user/register`,
      type: "post",
      data: { name: name, email: email, password, password },
      success: function (response) {
        if (response.success) {
          navigator("/auth/login/?register");
        }
      },
      error: function (response) {
        if (response.status == 400) {
          setLRerror(response.responseJSON.reason);
        } else {
          setLRerror("A regisztáció jelenleg nem elérhető!");
        }
      },
    });
  };
  const Logout = () => {
    Cookies.remove("_u");
    navigator("/");
  };
  return (
    <UserContext.Provider value={{ Login, LRerror, Register, user, Logout }}>
      <Auth />
      {children}
    </UserContext.Provider>
  );
};
