import { GoSearch } from "react-icons/go";
import { useLocation, Link } from "react-router-dom";
function Navbar() {
  const loc = useLocation();
  class NavLink {
    constructor(path, name) {
      this.path = path;
      this.name = name;
    }
  }
  const navLinks = [
    new NavLink("/", "Főoldal"),

    new NavLink("/#about", "Rólunk"),
    new NavLink("/#contact", "Kapcsolat"),
  ];
  return (
    <>
      <div className="nav_">
        <div className="row align-items-center">
          <div className="col-2">
            <span className="customt">DayFlow</span>
          </div>
          <div className="col-3">
            <div className="s">
              <div className="d-flex">
                <div className="si">
                  <GoSearch size={25} />
                </div>
                <input type="text" placeholder="Keresés" />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="d-flex justify-content-center align-items-center">
              {navLinks.map((i) => (
                <div className="mx-4">
                  <Link
                    to={i.path}
                    className={i.path == loc.pathname ? "active" : ""}>
                    {i.name}
                  </Link>
                </div>
              ))}
              <div className="mx-4">
                <Link to={"/auth/login"} className="custom_btn text-light">
                  Lépj be!
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
