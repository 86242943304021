import { useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
function _404() {
  const navigator = useNavigate();
  return (
    <>
      <Navbar />
      <h1 className="customt mt-5">DayFlow</h1>
      <div className="fw-bold text-center" style={{ fontSize: "10vw" }}>
        404
      </div>
      <h1 className="text-center">Az oldal nem található!</h1>
      <div className="mx-auto my-4">
        <button className="custom_btn" onClick={() => navigator("/")}>
          Vissaz a főoldalra
        </button>
      </div>
    </>
  );
}
export default _404;
