import { AiOutlineMail } from "react-icons/ai";
import { IoLockClosedOutline } from "react-icons/io5";
import { motion } from "framer-motion";
import { MdOutlinePerson } from "react-icons/md";
import Navbar from "../Components/Navbar";
import { useNavigate } from "react-router-dom";
import { useContext, useRef } from "react";
import { UserContext } from "../Providers/UserProvider";
function Register() {
  const navigator = useNavigate();
  const { Register, LRerror } = useContext(UserContext);
  const email = useRef();
  const password = useRef();
  const name = useRef();
  return (
    <>
      <Navbar />
      <div className="container">
        <div className="d-none d-sm-block">
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>

        <motion.div
          initial={{ opacity: 0, scale: 0.7 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.1 }}
          className="w-100 d-flex justify-content-center">
          <div className="login">
            <h3 className="text-center customt">DayFlow</h3>
            <h5 className="text-black-50 text-center mb-4">
              KÖSZÖNTELEK A DAYFLOW-NÁL
            </h5>
            <div className="">
              <div className="mezo mt-5">
                <div className="row g-0">
                  <div className="col-1">
                    <div className="icon">
                      <MdOutlinePerson />
                    </div>
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="w-100"
                      placeholder="Név"
                      ref={name}
                    />
                  </div>
                </div>
              </div>
              <div className="mezo mt-5">
                <div className="row g-0">
                  <div className="col-1">
                    <div className="icon">
                      <AiOutlineMail />
                    </div>
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="w-100"
                      placeholder="Email cím"
                      ref={email}
                    />
                  </div>
                </div>
              </div>
              <div className="mezo mt-5">
                <div className="row g-0">
                  <div className="col-1">
                    <div className="icon">
                      <IoLockClosedOutline />
                    </div>
                  </div>
                  <div className="col">
                    <input
                      type="password"
                      className="w-100"
                      placeholder="Jelszó"
                      ref={password}
                    />
                  </div>
                </div>
              </div>
              <p className="my-1 text-danger fw-bold text-center">{LRerror}</p>
              <button
                className="custom_btn w-100 mt-4 bg-warning"
                onClick={() => {
                  Register(
                    name.current.value,
                    email.current.value,
                    password.current.value
                  );
                }}>
                Regisztráció
              </button>
              <div className="d-flex justify-content-center flex-brake mt-4">
                <div
                  className="c-bl fw-bold cursor text-center"
                  onClick={() => navigator("/auth/login")}>
                  Már van fiókom!
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
}
export default Register;
