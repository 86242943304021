import { useContext, useEffect } from "react";
import { UserContext } from "../Providers/UserProvider";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
function Welcome() {
  const { user } = useContext(UserContext);

  const navigator = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigator("/dashboard");
    }, 1500);
    console.log(user);
  }, []);
  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <h3 className="text-center customt">DayFlow</h3>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.3 }}>
        <div>
          <h1 className="text-center fw-bold">
            Üdvözöllek, {user == null ? "" : user.name}!
          </h1>
        </div>
      </motion.div>
    </>
  );
}
export default Welcome;
