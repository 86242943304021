import { createContext, useContext, useEffect, useRef, useState } from "react";
import $ from "jquery";
import API from "../api.json";
import { UserContext } from "./UserProvider";
export const ClalendarContext = createContext();

export const CalendarProvider = ({ children }) => {
  const { user } = useContext(UserContext);
  const [colors, setColors] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedColor, setSelectedColor] = useState(1);
  const selectedColorRef = useRef(selectedColor);

  useEffect(() => {
    selectedColorRef.current = selectedColor;
  }, [selectedColor]);

  useEffect(() => {
    getColors();
  }, []);

  useEffect(() => {
    if (user != null) {
      getEvents();
    }
  }, [user]);

  const getColors = () => {
    $.ajax({
      url: `${API.api}/getColors`,
      type: "GET",
      success: function (_colors) {
        setColors(_colors);
      },
    });
  };

  useEffect(() => {
    console.log(selectedColor);
  }, [selectedColor]);

  const getEvents = () => {
    $.ajax({
      url: `${API.api}/getUserEvents`,
      type: "POST",
      data: { userid: user.userid, token: user.token },
      success: function (_events) {
        const formattedEvents = _events.map((event) => ({
          id: event.id,
          title: event.title,
          start: new Date(event.start),
          end: new Date(event.end),
          bgcolor: event.colorbg,
          bordercolor: event.colorb,
        }));
        setEvents(formattedEvents);
      },
    });
  };

  const _CreateEvent = (title, start, end) => {
    console.log("Creating Event with Color ID: ", selectedColor); // Debug print
    $.ajax({
      url: `${API.api}/createEvent`,
      type: "post",
      data: {
        userid: user.userid,
        token: user.token,
        title: title,
        start: start,
        end: end,
        colorid: selectedColorRef.current,
      },
      success: function (res) {
        if (res == true) {
          alert("upload success");
        }
      },
    });
  };
  return (
    <ClalendarContext.Provider
      value={{ colors, events, selectedColor, setSelectedColor, _CreateEvent }}>
      {children}
    </ClalendarContext.Provider>
  );
};
