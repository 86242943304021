import Cookies from "js-cookie";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Auth() {
  const loc = useLocation();
  const navigator = useNavigate();

  useEffect(() => {
    if (Cookies.get("_u" == null)) {
      if (
        !(loc.pathname == "/auth/login" || loc.pathname == "/auth/register")
      ) {
        navigator("/auth/login");
      }
    }
  }, []);
  return <></>;
}
export default Auth;
