import { RxDashboard } from "react-icons/rx";
import { LuCalendarDays } from "react-icons/lu";
import { MdOutlineAnalytics } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../Providers/UserProvider";
import { useContext } from "react";
function AppNavbar() {
  const loc = useLocation();
  const navigator = useNavigate();
  const { user, Logout } = useContext(UserContext);
  return (
    <>
      <div className="app-nav">
        <div className="row align-items-center">
          <div className="col-2">
            <div className="customt">DayFlow</div>
          </div>
          <div className="col">
            <div className="d-flex justify-content-center">
              <div
                className={`mx-4 cursor ${
                  loc.pathname == "/dashboard" ? "active_A" : ""
                }`}
                onClick={() => navigator("/dashboard")}>
                <div className="d-flex">
                  <RxDashboard size={25} className="me-2" />
                  <h5>Dashboard</h5>
                </div>
              </div>
              <div
                className={`mx-4 cursor ${
                  loc.pathname == "/calendar" ? "active_A" : ""
                }`}
                onClick={() => navigator("/calendar")}>
                <div className="d-flex">
                  <LuCalendarDays size={25} className="me-2" />
                  <h5>Naptár</h5>
                </div>
              </div>
              <div
                className={`mx-4 cursor ${
                  loc.pathname == "/analytics" ? "active_A" : ""
                }`}
                onClick={() => navigator("/analytics")}>
                <div className="d-flex">
                  <MdOutlineAnalytics size={25} className="me-2" />
                  <h5>Analitika</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="d-flex justify-content-end align-items-center">
              <div className="mx-5 fs-5">{user == null ? "" : user.name}</div>
              <div>
                <button className="custom_btn" onClick={() => Logout()}>
                  Kijelentkezés
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AppNavbar;
