import AppNavbar from "../../Components/AppNavbar";
import MiniCalendar from "../../Components/MiniCalendar";
import CalendarComponenet from "../../Components/CalendarComonent";

import { useContext, useEffect } from "react";
function Calendar() {
  return (
    <>
      <AppNavbar />
      <div className="calendar-container">
        <div className="row g-0">
          <div className="col-3 mt-4 sticky-top">
            <MiniCalendar />
          </div>
          <div className="col me-2 mb-5">
            <CalendarComponenet />
          </div>
        </div>
      </div>
    </>
  );
}
export default Calendar;
