import Navbar from "../Components/Navbar";
import { AiOutlineMail } from "react-icons/ai";
import { IoLockClosedOutline } from "react-icons/io5";
import { motion } from "framer-motion";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../Providers/UserProvider";
import { useContext, useEffect, useRef } from "react";
function Login() {
  const navigator = useNavigate();
  const email = useRef();
  const password = useRef();
  const { Login, LRerror, user } = useContext(UserContext);
  const loc = useLocation();
  useEffect(() => {
    if (user != null) {
      navigator("/welcome");
    }
  }, [user]);

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="d-none d-sm-block">
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>

        <motion.div
          initial={{ opacity: 0, scale: 0.7 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.1 }}
          className="w-100 d-flex justify-content-center">
          <div className="login">
            <div
              className="alert alert-success"
              style={{ display: loc.search == "?register" ? "block" : "none" }}>
              Sikeres regisztráció, jelentkezz be!
            </div>
            <h3 className="text-center customt">DayFlow</h3>
            <h5 className="text-black-50 text-center mb-4">
              KÖSZÖNTELEK A DAYFLOW-NÁL
            </h5>
            <div className="mt-4">
              <div className="mezo">
                <div className="row g-0">
                  <div className="col-1">
                    <div className="icon">
                      <AiOutlineMail />
                    </div>
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="w-100"
                      placeholder="Email cím"
                      ref={email}
                    />
                  </div>
                </div>
              </div>
              <div className="mezo mt-5">
                <div className="row g-0">
                  <div className="col-1">
                    <div className="icon">
                      <IoLockClosedOutline />
                    </div>
                  </div>
                  <div className="col">
                    <input
                      type="password"
                      className="w-100"
                      placeholder="Jelszó"
                      ref={password}
                    />
                  </div>
                </div>
              </div>
              <p className="my-1 text-center text-danger fw-bold">{LRerror}</p>
              <button
                className="custom_btn w-100 mt-4"
                onClick={() => {
                  Login(email.current.value, password.current.value);
                }}>
                Bejelentkezés
              </button>
              <div className="d-flex justify-content-between flex-brake mt-4">
                <div className="c-bl fw-bold cursor">
                  Elfelejtettem a jelszavam!
                </div>
                <div className="">|</div>
                <div
                  className="c-bl fw-bold cursor"
                  onClick={() => navigator("/auth/register")}>
                  Nincs fiókom!
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
}

export default Login;
