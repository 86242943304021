import AppNavbar from "../../Components/AppNavbar";
function App() {
  return (
    <>
      <AppNavbar />
      HAMAROSAN
    </>
  );
}
export default App;
